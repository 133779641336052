<template>
  <v-card
    fluid
    flat
    tile
    min-height="92vh"
    class="back mb-n15 pa-6 px-md-15"
  >
    <v-card-text
      v-for="list in lists"
      :key="list.id"
      class=""
    >
      <h3>{{list.name}}</h3>
      <automation-rules
        :list="list"
        :all-lists="lists"
        @refresh="refresh()"
      />
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import automationRules from "../components/automationRules.vue";
const _ = require("lodash");
export default {
  components: { automationRules },
  data: () => ({
    lists: [],
    rules: []
  }),
  computed: {
    ...mapGetters([
      "ENDPOINT",
      "dispoList",
      "teams",
      "selectedTeam",
      "selectedList"
    ])
  },
  async created() {
    await this.fetchLists();
    // this.getRules();
  },
  methods: {
    // async getRules(listId) {
    //     var res = await this.$http.get(
    //         `${this.ENDPOINT}/lists/${listId}?filter[fields][triggers]=true`
    //     );
    //     this.rules = res.body.triggers || [];
    //     console.log("Rules: ",this.rules);
    //     return this.rules;
    //     },
    async refresh() {
      console.log("Refresh in automation Parent");
      await this.fetchLists();
    },
    getTeamIds() {
      var teams = [];
      if (this.selectedTeam == "allTeams") {
        teams = _.map(this.teams, "id");
      } else {
        teams.push(this.selectedTeam);
      }
      teams = _.without(teams, "allTeams");
      return teams;
    },
    async fetchLists() {
      this.lists = [];
      var teamIds = await this.getTeamIds();
      await this.$http
        .post(`${this.ENDPOINT}/lists/fetch`, { teams: teamIds })
        .then(res => {
          this.lists = res.body;
          console.log("All lists", this.lists);
        })
        .catch(e => {
          console.log(e);
        });
    }
  }
};
</script>

<style>
</style>